import validate from "/home/eventms/sources/prod/fe/node_modules/nuxt/dist/pages/runtime/validate.js";
import livecasino_45global from "/home/eventms/sources/prod/fe/middleware/livecasino.global.ts";
import promotion_45global from "/home/eventms/sources/prod/fe/middleware/promotion.global.ts";
import set_45layout_45global from "/home/eventms/sources/prod/fe/middleware/setLayout.global.ts";
export const globalMiddleware = [
  validate,
  livecasino_45global,
  promotion_45global,
  set_45layout_45global
]
export const namedMiddleware = {
  auth: () => import("/home/eventms/sources/prod/fe/middleware/auth.ts"),
  "page-desktop": () => import("/home/eventms/sources/prod/fe/middleware/pageDesktop.ts"),
  "page-mobile": () => import("/home/eventms/sources/prod/fe/middleware/pageMobile.ts"),
  "un-auth": () => import("/home/eventms/sources/prod/fe/middleware/unAuth.ts")
}