import isEmpty from 'lodash/isEmpty'
import { isCacheCrawlerAgent, isBotChromeLighthouse } from '@/helper'
import { ISeo } from '~/types/seo'
import { seoApi } from '~/api/seo'
import { useAppStore } from '~/store/app'
export default {

  initDevice() {
    const _self = this as any
    const device = useCookie('setDevice', { path: '/', maxAge: 60 * 60 * 24 * 10 })

    const setDeviceCookie = (deviceType: string, osType: string) => {
      const _device = useCookie('device', { path: '/' })
      const os = useCookie('os', { path: '/' })
      _device.value = deviceType
      os.value = osType
      _self.mutate({ property: 'device', with: deviceType })
    }

    if (device.value) {
      _self.mutate({ property: 'device', with: device.value })
    }

    const route = useRoute()
    if (route.query.device) {
      device.value = route.query.device as string
      setDeviceCookie(device.value, device.value === 'mobile' ? 'ios' : '')
    } else {
      const { $device } = useNuxtApp()
      const deviceType = $device.isDesktop ? 'desktop' : 'mobile'
      const osType = $device.isDesktop ? 'desktop' : $device.isIos ? 'ios' : 'android'
      setDeviceCookie(deviceType, osType)
    }
  },

  async getSeoData() {
    const config = useRuntimeConfig()
    const { SEO } = seoApi()
    const { $pinia } = useNuxtApp()
    const store = useAppStore($pinia)
    const iSeoList: ISeo[] = []
    try {
      const rs = await fetch(`${config.public.API_BASE_URL}${SEO}`)
      const resultAPI = await rs.json() as any
      if (resultAPI.data?.status === 'OK') {
        const result = resultAPI.data.data
        result.forEach((ISeo: ISeo) => {
          const { children, ...parentWithoutChildren } = ISeo
          iSeoList.push(parentWithoutChildren)
          if (ISeo.children) {
            ISeo.children.forEach((child: ISeo) => {
              iSeoList.push(child)
            })
          }
        })
        store.setSeoData(iSeoList)
      }
    } catch (error) {
      console.error(error)
    }
  },

  initAff() {
    const _self = this as any
    const route = useRoute()
    const headers = useRequestHeaders()
    const config = useRuntimeConfig()

    const setCookieAndMutate = (key: string, value: string | null) => {
      const cookie = useCookie(key, { path: '/', maxAge: 60 * 60 * 24 * 10 })
      if (value !== null) {
        cookie.value = value
        _self.mutate({ property: `affInfo.${key}`, with: value })
      } else if (cookie.value) {
        _self.mutate({ property: `affInfo.${key}`, with: cookie.value })
      }
    }

    setCookieAndMutate('source', (route.query.source as string) || headers.host || null)
    setCookieAndMutate(
      'saleAdvised',
      route.query.v || (headers.host === config.public.SALE_DOMAIN ? config.public.SALE_ID_DEFAULT : null)
    )
    setCookieAndMutate('aff_id', route.query.a as string || null)
    setCookieAndMutate('utm_source', route.query.utm_source as string || null)
    setCookieAndMutate('utm_medium', route.query.utm_medium as string || null)
    setCookieAndMutate('utm_campaign', route.query.utm_campaign as string || null)
    setCookieAndMutate('utm_term', route.query.utm_term as string || null)
    setCookieAndMutate('utm_content', route.query.utm_content as string || null)
  },

  nuxtServerInit() {
    this.getSeoData()
    const brand_code = useCookie('brand_code', {
      path: '/',
      maxAge: 60 * 60 * 24 * 10
    })
    const language = useCookie('language', {
      path: '/',
      maxAge: 60 * 60 * 24 * 10
    })
    language.value = 'vi'
    brand_code.value = useRuntimeConfig().public.BRAND_CODE

    const _self = this as any
    const headers = useRequestHeaders()
    const userAgent = headers['user-agent'] || ''
    const route = useRoute()

    if (isCacheCrawlerAgent(userAgent)) {
      _self.mutate({ property: 'isCacheCrawlerAgent', with: true })
    }

    if (isBotChromeLighthouse(userAgent)) {
      _self.mutate({ property: 'isBotChromeLighthouse', with: true })
    }

    const user = useCookie('user')
    _self.mutate({ property: 'setCurrentUser', with: user.value || null })

    const config = useRuntimeConfig()
    if (+config.public.MAINTAIN === 1 && route.path !== '/maintenance') {
      _self.mutate({ property: 'maintain', with: +config.public.MAINTAIN })
      navigateTo('/maintenance')
    }

    this.initDevice()
    this.initAff()
  }
}
