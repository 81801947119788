import { default as banklxdiMiEBQDMeta } from "/home/eventms/sources/prod/fe/pages/account/bank.vue?macro=true";
import { default as bankZ1zgfZY38EMeta } from "/home/eventms/sources/prod/fe/pages/account/deposit/bank.vue?macro=true";
import { default as cardViSFGjyRgAMeta } from "/home/eventms/sources/prod/fe/pages/account/deposit/card.vue?macro=true";
import { default as codepayhwGetUerjuMeta } from "/home/eventms/sources/prod/fe/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay23I8MpkOWyZMeta } from "/home/eventms/sources/prod/fe/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptorgUSsXITu5Meta } from "/home/eventms/sources/prod/fe/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletcudxyZW30ZMeta } from "/home/eventms/sources/prod/fe/pages/account/deposit/ewallet.vue?macro=true";
import { default as betcOQRzRjepTMeta } from "/home/eventms/sources/prod/fe/pages/account/history/bet.vue?macro=true";
import { default as transactionx1kkrmKA54Meta } from "/home/eventms/sources/prod/fe/pages/account/history/transaction.vue?macro=true";
import { default as historyMGm6SIj65vMeta } from "/home/eventms/sources/prod/fe/pages/account/history.vue?macro=true";
import { default as p2pnMm5qv1z0GMeta } from "/home/eventms/sources/prod/fe/pages/account/p2p.vue?macro=true";
import { default as profilel2SWhW0goPMeta } from "/home/eventms/sources/prod/fe/pages/account/profile.vue?macro=true";
import { default as trading_45p2pbfdEYx8qPIMeta } from "/home/eventms/sources/prod/fe/pages/account/trading-p2p.vue?macro=true";
import { default as bankDRZcpnZdeAMeta } from "/home/eventms/sources/prod/fe/pages/account/withdraw/bank.vue?macro=true";
import { default as cardxVscgUNWvFMeta } from "/home/eventms/sources/prod/fe/pages/account/withdraw/card.vue?macro=true";
import { default as cryptosUqyTr3HfqMeta } from "/home/eventms/sources/prod/fe/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountetQrQBAHLVMeta } from "/home/eventms/sources/prod/fe/pages/account.vue?macro=true";
import { default as base_45buttonpz7TKXQ2S3Meta } from "/home/eventms/sources/prod/fe/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downWUjhRucVbJMeta } from "/home/eventms/sources/prod/fe/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radiorMEOEVLcF6Meta } from "/home/eventms/sources/prod/fe/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgsseK7USDCuMeta } from "/home/eventms/sources/prod/fe/pages/dev/base-img.vue?macro=true";
import { default as base_45inputZkPMbppHFsMeta } from "/home/eventms/sources/prod/fe/pages/dev/base-input.vue?macro=true";
import { default as base_45loading75NOxktApUMeta } from "/home/eventms/sources/prod/fe/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalwgO8reGnYuMeta } from "/home/eventms/sources/prod/fe/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputOcjik5bkxDMeta } from "/home/eventms/sources/prod/fe/pages/dev/base-money-input.vue?macro=true";
import { default as base_45pagination3wvFRCqACwMeta } from "/home/eventms/sources/prod/fe/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankoUl7cbTQntMeta } from "/home/eventms/sources/prod/fe/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemQADULvnwBTMeta } from "/home/eventms/sources/prod/fe/pages/dev/game-item.vue?macro=true";
import { default as indexUGLEzx4lk0Meta } from "/home/eventms/sources/prod/fe/pages/dev/index.vue?macro=true";
import { default as _91type_93ToKEN8T6aFMeta } from "/home/eventms/sources/prod/fe/pages/game/[type].vue?macro=true";
import { default as gameo1vkzDyh4KMeta } from "/home/eventms/sources/prod/fe/pages/game.vue?macro=true";
import { default as _91alias_93NXxTVHJRtiMeta } from "/home/eventms/sources/prod/fe/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieulYpT6blVELMeta } from "/home/eventms/sources/prod/fe/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93Zpis9KFJxGMeta } from "/home/eventms/sources/prod/fe/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45dan3YcT5xyDFaMeta } from "/home/eventms/sources/prod/fe/pages/huong-dan.vue?macro=true";
import { default as indexp8GEWFkcT1Meta } from "/home/eventms/sources/prod/fe/pages/index.vue?macro=true";
import { default as _91id_93ohDmPbYWFvMeta } from "/home/eventms/sources/prod/fe/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexJGCo9ZcEwxMeta } from "/home/eventms/sources/prod/fe/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93s1F82zGjTOMeta } from "/home/eventms/sources/prod/fe/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as khuyen_45mai8nzzfM2X83Meta } from "/home/eventms/sources/prod/fe/pages/khuyen-mai.vue?macro=true";
import { default as _91type_937NpIMrUzvjMeta } from "/home/eventms/sources/prod/fe/pages/livecasino/[type].vue?macro=true";
import { default as livecasinoSCNXhuxWoaMeta } from "/home/eventms/sources/prod/fe/pages/livecasino.vue?macro=true";
import { default as reset_45passwordwQNO5b18u4Meta } from "/home/eventms/sources/prod/fe/pages/reset-password.vue?macro=true";
import { default as search2OD0laB3EtMeta } from "/home/eventms/sources/prod/fe/pages/search.vue?macro=true";
import { default as _91id_93SIx8u7zIA4Meta } from "/home/eventms/sources/prod/fe/pages/the-thao/[id].vue?macro=true";
import { default as indexTKTJOfGsjoMeta } from "/home/eventms/sources/prod/fe/pages/the-thao/index.vue?macro=true";
import { default as index4rx4AulTLcMeta } from "/home/eventms/sources/prod/fe/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93KDyjh90xgxMeta } from "/home/eventms/sources/prod/fe/pages/tin-tuc/[alias].vue?macro=true";
import { default as indexWwbEqR5sFUMeta } from "/home/eventms/sources/prod/fe/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93Rfhhc6TRdgMeta } from "/home/eventms/sources/prod/fe/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tucnXa08mMnb3Meta } from "/home/eventms/sources/prod/fe/pages/tin-tuc.vue?macro=true";
import { default as verify_45maillefpzg9G5GMeta } from "/home/eventms/sources/prod/fe/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountetQrQBAHLVMeta?.name ?? "account",
    path: accountetQrQBAHLVMeta?.path ?? "/account",
    children: [
  {
    name: banklxdiMiEBQDMeta?.name ?? "account-bank",
    path: banklxdiMiEBQDMeta?.path ?? "bank",
    meta: banklxdiMiEBQDMeta || {},
    alias: banklxdiMiEBQDMeta?.alias || [],
    redirect: banklxdiMiEBQDMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bankZ1zgfZY38EMeta?.name ?? "account-deposit-bank",
    path: bankZ1zgfZY38EMeta?.path ?? "deposit/bank",
    meta: bankZ1zgfZY38EMeta || {},
    alias: bankZ1zgfZY38EMeta?.alias || [],
    redirect: bankZ1zgfZY38EMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardViSFGjyRgAMeta?.name ?? "account-deposit-card",
    path: cardViSFGjyRgAMeta?.path ?? "deposit/card",
    meta: cardViSFGjyRgAMeta || {},
    alias: cardViSFGjyRgAMeta?.alias || [],
    redirect: cardViSFGjyRgAMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayhwGetUerjuMeta?.name ?? "account-deposit-codepay",
    path: codepayhwGetUerjuMeta?.path ?? "deposit/codepay",
    meta: codepayhwGetUerjuMeta || {},
    alias: codepayhwGetUerjuMeta?.alias || [],
    redirect: codepayhwGetUerjuMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay23I8MpkOWyZMeta?.name ?? "account-deposit-codepay2",
    path: codepay23I8MpkOWyZMeta?.path ?? "deposit/codepay2",
    meta: codepay23I8MpkOWyZMeta || {},
    alias: codepay23I8MpkOWyZMeta?.alias || [],
    redirect: codepay23I8MpkOWyZMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptorgUSsXITu5Meta?.name ?? "account-deposit-crypto",
    path: cryptorgUSsXITu5Meta?.path ?? "deposit/crypto",
    meta: cryptorgUSsXITu5Meta || {},
    alias: cryptorgUSsXITu5Meta?.alias || [],
    redirect: cryptorgUSsXITu5Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletcudxyZW30ZMeta?.name ?? "account-deposit-ewallet",
    path: ewalletcudxyZW30ZMeta?.path ?? "deposit/ewallet",
    meta: ewalletcudxyZW30ZMeta || {},
    alias: ewalletcudxyZW30ZMeta?.alias || [],
    redirect: ewalletcudxyZW30ZMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  },
  {
    name: historyMGm6SIj65vMeta?.name ?? "account-history",
    path: historyMGm6SIj65vMeta?.path ?? "history",
    children: [
  {
    name: betcOQRzRjepTMeta?.name ?? "account-history-bet",
    path: betcOQRzRjepTMeta?.path ?? "bet",
    meta: betcOQRzRjepTMeta || {},
    alias: betcOQRzRjepTMeta?.alias || [],
    redirect: betcOQRzRjepTMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionx1kkrmKA54Meta?.name ?? "account-history-transaction",
    path: transactionx1kkrmKA54Meta?.path ?? "transaction",
    meta: transactionx1kkrmKA54Meta || {},
    alias: transactionx1kkrmKA54Meta?.alias || [],
    redirect: transactionx1kkrmKA54Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyMGm6SIj65vMeta || {},
    alias: historyMGm6SIj65vMeta?.alias || [],
    redirect: historyMGm6SIj65vMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: p2pnMm5qv1z0GMeta?.name ?? "account-p2p",
    path: p2pnMm5qv1z0GMeta?.path ?? "p2p",
    meta: p2pnMm5qv1z0GMeta || {},
    alias: p2pnMm5qv1z0GMeta?.alias || [],
    redirect: p2pnMm5qv1z0GMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/p2p.vue").then(m => m.default || m)
  },
  {
    name: profilel2SWhW0goPMeta?.name ?? "account-profile",
    path: profilel2SWhW0goPMeta?.path ?? "profile",
    meta: profilel2SWhW0goPMeta || {},
    alias: profilel2SWhW0goPMeta?.alias || [],
    redirect: profilel2SWhW0goPMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2pbfdEYx8qPIMeta?.name ?? "account-trading-p2p",
    path: trading_45p2pbfdEYx8qPIMeta?.path ?? "trading-p2p",
    meta: trading_45p2pbfdEYx8qPIMeta || {},
    alias: trading_45p2pbfdEYx8qPIMeta?.alias || [],
    redirect: trading_45p2pbfdEYx8qPIMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bankDRZcpnZdeAMeta?.name ?? "account-withdraw-bank",
    path: bankDRZcpnZdeAMeta?.path ?? "withdraw/bank",
    meta: bankDRZcpnZdeAMeta || {},
    alias: bankDRZcpnZdeAMeta?.alias || [],
    redirect: bankDRZcpnZdeAMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardxVscgUNWvFMeta?.name ?? "account-withdraw-card",
    path: cardxVscgUNWvFMeta?.path ?? "withdraw/card",
    meta: cardxVscgUNWvFMeta || {},
    alias: cardxVscgUNWvFMeta?.alias || [],
    redirect: cardxVscgUNWvFMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptosUqyTr3HfqMeta?.name ?? "account-withdraw-crypto",
    path: cryptosUqyTr3HfqMeta?.path ?? "withdraw/crypto",
    meta: cryptosUqyTr3HfqMeta || {},
    alias: cryptosUqyTr3HfqMeta?.alias || [],
    redirect: cryptosUqyTr3HfqMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountetQrQBAHLVMeta || {},
    alias: accountetQrQBAHLVMeta?.alias || [],
    redirect: accountetQrQBAHLVMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonpz7TKXQ2S3Meta?.name ?? "dev-base-button",
    path: base_45buttonpz7TKXQ2S3Meta?.path ?? "/dev/base-button",
    meta: base_45buttonpz7TKXQ2S3Meta || {},
    alias: base_45buttonpz7TKXQ2S3Meta?.alias || [],
    redirect: base_45buttonpz7TKXQ2S3Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downWUjhRucVbJMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downWUjhRucVbJMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downWUjhRucVbJMeta || {},
    alias: base_45count_45downWUjhRucVbJMeta?.alias || [],
    redirect: base_45count_45downWUjhRucVbJMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radiorMEOEVLcF6Meta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radiorMEOEVLcF6Meta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radiorMEOEVLcF6Meta || {},
    alias: base_45dropdown_45radiorMEOEVLcF6Meta?.alias || [],
    redirect: base_45dropdown_45radiorMEOEVLcF6Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgsseK7USDCuMeta?.name ?? "dev-base-img",
    path: base_45imgsseK7USDCuMeta?.path ?? "/dev/base-img",
    meta: base_45imgsseK7USDCuMeta || {},
    alias: base_45imgsseK7USDCuMeta?.alias || [],
    redirect: base_45imgsseK7USDCuMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputZkPMbppHFsMeta?.name ?? "dev-base-input",
    path: base_45inputZkPMbppHFsMeta?.path ?? "/dev/base-input",
    meta: base_45inputZkPMbppHFsMeta || {},
    alias: base_45inputZkPMbppHFsMeta?.alias || [],
    redirect: base_45inputZkPMbppHFsMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loading75NOxktApUMeta?.name ?? "dev-base-loading",
    path: base_45loading75NOxktApUMeta?.path ?? "/dev/base-loading",
    meta: base_45loading75NOxktApUMeta || {},
    alias: base_45loading75NOxktApUMeta?.alias || [],
    redirect: base_45loading75NOxktApUMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalwgO8reGnYuMeta?.name ?? "dev-base-modal",
    path: base_45modalwgO8reGnYuMeta?.path ?? "/dev/base-modal",
    meta: base_45modalwgO8reGnYuMeta || {},
    alias: base_45modalwgO8reGnYuMeta?.alias || [],
    redirect: base_45modalwgO8reGnYuMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputOcjik5bkxDMeta?.name ?? "dev-base-money-input",
    path: base_45money_45inputOcjik5bkxDMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputOcjik5bkxDMeta || {},
    alias: base_45money_45inputOcjik5bkxDMeta?.alias || [],
    redirect: base_45money_45inputOcjik5bkxDMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45pagination3wvFRCqACwMeta?.name ?? "dev-base-pagination",
    path: base_45pagination3wvFRCqACwMeta?.path ?? "/dev/base-pagination",
    meta: base_45pagination3wvFRCqACwMeta || {},
    alias: base_45pagination3wvFRCqACwMeta?.alias || [],
    redirect: base_45pagination3wvFRCqACwMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankoUl7cbTQntMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankoUl7cbTQntMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankoUl7cbTQntMeta || {},
    alias: base_45select_45bankoUl7cbTQntMeta?.alias || [],
    redirect: base_45select_45bankoUl7cbTQntMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemQADULvnwBTMeta?.name ?? "dev-game-item",
    path: game_45itemQADULvnwBTMeta?.path ?? "/dev/game-item",
    meta: game_45itemQADULvnwBTMeta || {},
    alias: game_45itemQADULvnwBTMeta?.alias || [],
    redirect: game_45itemQADULvnwBTMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexUGLEzx4lk0Meta?.name ?? "dev",
    path: indexUGLEzx4lk0Meta?.path ?? "/dev",
    meta: indexUGLEzx4lk0Meta || {},
    alias: indexUGLEzx4lk0Meta?.alias || [],
    redirect: indexUGLEzx4lk0Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameo1vkzDyh4KMeta?.name ?? "game",
    path: gameo1vkzDyh4KMeta?.path ?? "/game",
    children: [
  {
    name: _91type_93ToKEN8T6aFMeta?.name ?? "game-type",
    path: _91type_93ToKEN8T6aFMeta?.path ?? ":type",
    meta: _91type_93ToKEN8T6aFMeta || {},
    alias: _91type_93ToKEN8T6aFMeta?.alias || [],
    redirect: _91type_93ToKEN8T6aFMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameo1vkzDyh4KMeta || {},
    alias: gameo1vkzDyh4KMeta?.alias || [],
    redirect: gameo1vkzDyh4KMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieulYpT6blVELMeta?.name ?? "gioi-thieu",
    path: gioi_45thieulYpT6blVELMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93NXxTVHJRtiMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93NXxTVHJRtiMeta?.path ?? ":alias",
    meta: _91alias_93NXxTVHJRtiMeta || {},
    alias: _91alias_93NXxTVHJRtiMeta?.alias || [],
    redirect: _91alias_93NXxTVHJRtiMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieulYpT6blVELMeta || {},
    alias: gioi_45thieulYpT6blVELMeta?.alias || [],
    redirect: gioi_45thieulYpT6blVELMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45dan3YcT5xyDFaMeta?.name ?? "huong-dan",
    path: huong_45dan3YcT5xyDFaMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93Zpis9KFJxGMeta?.name ?? "huong-dan-alias",
    path: _91alias_93Zpis9KFJxGMeta?.path ?? ":alias",
    meta: _91alias_93Zpis9KFJxGMeta || {},
    alias: _91alias_93Zpis9KFJxGMeta?.alias || [],
    redirect: _91alias_93Zpis9KFJxGMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45dan3YcT5xyDFaMeta || {},
    alias: huong_45dan3YcT5xyDFaMeta?.alias || [],
    redirect: huong_45dan3YcT5xyDFaMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexp8GEWFkcT1Meta?.name ?? "index",
    path: indexp8GEWFkcT1Meta?.path ?? "/",
    meta: indexp8GEWFkcT1Meta || {},
    alias: indexp8GEWFkcT1Meta?.alias || [],
    redirect: indexp8GEWFkcT1Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45mai8nzzfM2X83Meta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93ohDmPbYWFvMeta?.name ?? "khuyen-mai-id",
    path: _91id_93ohDmPbYWFvMeta?.path ?? ":id",
    meta: _91id_93ohDmPbYWFvMeta || {},
    alias: _91id_93ohDmPbYWFvMeta?.alias || [],
    redirect: _91id_93ohDmPbYWFvMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJGCo9ZcEwxMeta?.name ?? "khuyen-mai",
    path: indexJGCo9ZcEwxMeta?.path ?? "",
    meta: indexJGCo9ZcEwxMeta || {},
    alias: indexJGCo9ZcEwxMeta?.alias || [],
    redirect: indexJGCo9ZcEwxMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93s1F82zGjTOMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93s1F82zGjTOMeta?.path ?? "the-loai/:id",
    meta: _91id_93s1F82zGjTOMeta || {},
    alias: _91id_93s1F82zGjTOMeta?.alias || [],
    redirect: _91id_93s1F82zGjTOMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  }
],
    name: khuyen_45mai8nzzfM2X83Meta?.name ?? undefined,
    meta: khuyen_45mai8nzzfM2X83Meta || {},
    alias: khuyen_45mai8nzzfM2X83Meta?.alias || [],
    redirect: khuyen_45mai8nzzfM2X83Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasinoSCNXhuxWoaMeta?.name ?? "livecasino",
    path: livecasinoSCNXhuxWoaMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_937NpIMrUzvjMeta?.name ?? "livecasino-type",
    path: _91type_937NpIMrUzvjMeta?.path ?? ":type",
    meta: _91type_937NpIMrUzvjMeta || {},
    alias: _91type_937NpIMrUzvjMeta?.alias || [],
    redirect: _91type_937NpIMrUzvjMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasinoSCNXhuxWoaMeta || {},
    alias: livecasinoSCNXhuxWoaMeta?.alias || [],
    redirect: livecasinoSCNXhuxWoaMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwQNO5b18u4Meta?.name ?? "reset-password",
    path: reset_45passwordwQNO5b18u4Meta?.path ?? "/reset-password",
    meta: reset_45passwordwQNO5b18u4Meta || {},
    alias: reset_45passwordwQNO5b18u4Meta?.alias || [],
    redirect: reset_45passwordwQNO5b18u4Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: search2OD0laB3EtMeta?.name ?? "search",
    path: search2OD0laB3EtMeta?.path ?? "/search",
    meta: search2OD0laB3EtMeta || {},
    alias: search2OD0laB3EtMeta?.alias || [],
    redirect: search2OD0laB3EtMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SIx8u7zIA4Meta?.name ?? "the-thao-id",
    path: _91id_93SIx8u7zIA4Meta?.path ?? "/the-thao/:id",
    meta: _91id_93SIx8u7zIA4Meta || {},
    alias: _91id_93SIx8u7zIA4Meta?.alias || [],
    redirect: _91id_93SIx8u7zIA4Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTKTJOfGsjoMeta?.name ?? "the-thao",
    path: indexTKTJOfGsjoMeta?.path ?? "/the-thao",
    meta: indexTKTJOfGsjoMeta || {},
    alias: indexTKTJOfGsjoMeta?.alias || [],
    redirect: indexTKTJOfGsjoMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: index4rx4AulTLcMeta?.name ?? "the-thao-lich-thi-dau",
    path: index4rx4AulTLcMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: index4rx4AulTLcMeta || {},
    alias: index4rx4AulTLcMeta?.alias || [],
    redirect: index4rx4AulTLcMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tucnXa08mMnb3Meta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_93KDyjh90xgxMeta?.name ?? "tin-tuc-alias",
    path: _91alias_93KDyjh90xgxMeta?.path ?? ":alias",
    meta: _91alias_93KDyjh90xgxMeta || {},
    alias: _91alias_93KDyjh90xgxMeta?.alias || [],
    redirect: _91alias_93KDyjh90xgxMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: indexWwbEqR5sFUMeta?.name ?? "tin-tuc",
    path: indexWwbEqR5sFUMeta?.path ?? "",
    meta: indexWwbEqR5sFUMeta || {},
    alias: indexWwbEqR5sFUMeta?.alias || [],
    redirect: indexWwbEqR5sFUMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93Rfhhc6TRdgMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93Rfhhc6TRdgMeta?.path ?? "the-loai/:Slug",
    meta: _91Slug_93Rfhhc6TRdgMeta || {},
    alias: _91Slug_93Rfhhc6TRdgMeta?.alias || [],
    redirect: _91Slug_93Rfhhc6TRdgMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tucnXa08mMnb3Meta?.name ?? undefined,
    meta: tin_45tucnXa08mMnb3Meta || {},
    alias: tin_45tucnXa08mMnb3Meta?.alias || [],
    redirect: tin_45tucnXa08mMnb3Meta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45maillefpzg9G5GMeta?.name ?? "verify-mail",
    path: verify_45maillefpzg9G5GMeta?.path ?? "/verify-mail",
    meta: verify_45maillefpzg9G5GMeta || {},
    alias: verify_45maillefpzg9G5GMeta?.alias || [],
    redirect: verify_45maillefpzg9G5GMeta?.redirect || undefined,
    component: () => import("/home/eventms/sources/prod/fe/pages/verify-mail.vue").then(m => m.default || m)
  }
]