// @ts-ignore
import Vue3Sanitize from "vue-3-sanitize";
import sanitizeHtml from "sanitize-html";

export default defineNuxtPlugin((nuxtApp) => {
    const overrideOptions = {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat("img", "p"),
        nonBooleanAttributes: sanitizeHtml.defaults.nonBooleanAttributes.concat("style"),
        allowedAttributes: false,
        allowedSchemes: ['data', 'http', 'https']
    };
    nuxtApp.vueApp.use(Vue3Sanitize, overrideOptions);
});
