import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.css'

export default defineNuxtPlugin(({ $config }) => {
  const route = useRoute()

  const getRoute = (clientRoute: any) =>
    clientRoute ||
    route || {
      name: '',
      params: {
        slug: ''
      },
      fullPath: ''
    }

  const Toast = Swal.mixin({
    toast: true,
    showConfirmButton: false,
    timerProgressBar: false,
    showCloseButton: false,
    position: 'bottom-start'
  })

  const fullSrc = (src: string) => {
    return !/^(https?:)?\/\//.test(src) ? `${$config.public.STATIC_BASE_URL}${src}` : src
  }

  const iconType = (icon: string) => {
    const iconSrc = `/assets/images/icon/alert-${icon}.svg`
    return `<img src='${iconSrc}' alt='alert-${icon}' />`
  }

  const getContent = (content: string, icon: string) => {
    const loading = `<img src='/assets/images/icon/loading-${icon}.svg' alt='alert-loading' />`
    return `<div class='content'><span>${content}</span>${loading}</div>`
  }

  const alertMsg = (icon: string, title: string, timer = 4000) =>
    Toast.fire({
      title: iconType(icon),
      html: getContent(title, icon),
      customClass: {
        container: icon
      },
      timer
    })

  return {
    provide: {
      getRoute,
      alertMsg
    }
  }
})
