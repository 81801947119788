import { Modal } from 'bootstrap'

export default defineNuxtPlugin(({ $bus }) => {
  const getVariableStyle = (el: Element | HTMLElement) => {
    const computedStyle = getComputedStyle(el)
    // const backdropZIndex = computedStyle.getPropertyValue('--bs-backdrop-zindex');
    const modalZIndex = computedStyle.getPropertyValue('--bs-modal-zindex')

    return {
      backdropZIndex: +modalZIndex,
      modalZIndex: +modalZIndex
    }
  }

  const setAttributeModalBackdrop = (id: string) => {
    const els = document.getElementsByClassName('modal-backdrop')
    Array.from(els).forEach((_el) => {
      !_el?.getAttribute('data-modal-id') && _el?.setAttribute('data-modal-id', id)
    })
  }

  const handleEvent = (id: string, evtName: string, evtCustom: string) => {
    const el = document.getElementById(id)
    el?.addEventListener(evtName, (event) => {
      ;($bus as any).$emit(evtCustom, { event, modalId: id })
      if (evtName === 'shown.bs.modal') {
        // Set modal backdrop attribute of modal id
        setAttributeModalBackdrop(id)

        // Set z-index for modal and backdrop of child modals
        const parentModalId: string = el.getAttribute('data-parent-modal-id') || ''
        const parentModalEl = document.getElementById(parentModalId)
        if (parentModalEl) {
          const { backdropZIndex, modalZIndex } = getVariableStyle(parentModalEl)
          // Set z-index of child modals
          el?.style.setProperty('z-index', (modalZIndex + 100).toString())
          // Set backdrop modal z-index of child modals
          const backdropElement = document.querySelector('.modal-backdrop[data-modal-id="' + id + '"]')
          if (backdropElement instanceof HTMLElement) {
            backdropElement.style.setProperty('z-index', (backdropZIndex + 90).toString())
          }
        }
      }
    })
  }

  const removeStyle = () => {
    const modalActiveEls = document.querySelectorAll('.modal.show')
    const isClassModal = document.body.classList.contains('modal-open')
    const attrs = ['overflow', 'padding-right']
    !Array.from(modalActiveEls).length &&
    isClassModal &&
    attrs.forEach((attr) => {
      document.body.style.removeProperty(attr)
    })
  }

  const removeBackdrop = (id: string) => {
    const el = document.getElementsByClassName('modal-backdrop')
    Array.from(el).forEach((_el) => {
      const modalId = _el?.getAttribute('data-modal-id')
      modalId === id && _el?.remove()
    })
  }

  const showModal = (id: string) => {
    removeStyle()
    removeBackdrop(id)
    const modalElement = document.getElementById(id)
    if (modalElement) {
      const modal = new Modal(modalElement, {})
      handleEvent(id, 'shown.bs.modal', 'bv::shown::modal')
      handleEvent(id, 'show.bs.modal', 'bv::show::modal')
      modal.show()
    }
  }

  const hideModal = (id: string) => {
    const modalElement = document.getElementById(id)

    if (modalElement) {
      const modal = Modal.getInstance(modalElement)
      handleEvent(id, 'hidden.bs.modal', 'bv::hidden::modal')
      handleEvent(id, 'hide.bs.modal', 'bv::hide::modal')
      modal?.hide()
    }
    removeBackdrop(id)
  }

  const toggleModal = (id: string) => {
    const modalElement = document.getElementById(id)
    if (modalElement) {
      const modal = Modal.getInstance(modalElement)
      !modal && new Modal(modalElement)
      modal?.toggle()
    }
  }

  return {
    provide: {
      bvModal: {
        show: showModal,
        hide: hideModal,
        toggle: toggleModal,
        removeBackdrop
      }
    }
  }
})
