import { PAGE_URLS } from '~/config/page-url'

export default defineNuxtRouteMiddleware((to, from) => {
  const redirects: Record<string, string> = {
    '/khuyen-mai/': PAGE_URLS.PROMOTION
  }
  if (redirects[to.fullPath]) {
    return navigateTo(redirects[to.fullPath])
  }
})
